<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <v-toolbar dense flat color="green darken-2" dark>
                    <v-toolbar-title>Invite customer</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <p>When you submit this form, an email invitation will be sent.</p>
                </v-card-text>
                <v-form @submit.prevent="invite" onSubmit="return false;" @keyup.enter.native.prevent="invite" class="mx-4 pb-6">
                    <v-text-field v-model="displayName" ref="displayNameInput" dense solo color="green darken-2" hint="Customer's full name, first name, or a nickname. The customer can change it later." placeholder="Name" :error-messages="displayNameInputError">
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'user']" fixed-width></font-awesome-icon>
                        </template>
                    </v-text-field>
                    <v-text-field v-model="email" ref="emailInput" dense solo color="green darken-2" hint="This will be the customer's email. The customer can change it later." placeholder="Email" :error-messages="emailInputError">
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'envelope']" fixed-width></font-awesome-icon>
                        </template>
                    </v-text-field>
                    <v-row no-gutters justify="center">
                    <v-btn @click="invite" elevation="4" color="green darken-2 white--text" class="mt-4">
                        <font-awesome-icon :icon="['fas', 'paper-plane']" fixed-width class="mr-2"></font-awesome-icon>
                        Invite
                    </v-btn>
                    </v-row>
                </v-form>
            </v-card>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send an invitation email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState } from 'vuex';
import { isValidEmail } from '@/sdk/input';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        isViewReady: false,
        email: '',
        displayName: '',
        submitTimestamp: null,
        redirect: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        displayNameInputError: null,
        emailInputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
            // organizationList: (state) => state.organizationList,
        }),
    },
    watch: {
        focus() {
            if (!this.displayName) {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('displayNameInput'); }, 1);
                });
                return;
            }
            if (!this.email) {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('emailInput'); }, 1);
                });
            }
        },
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.displayNameInputError = null;
            this.emailInputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        async invite() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.resetErrors();
                if (typeof this.displayName !== 'string' || this.displayName.trim().length === 0) {
                    this.displayNameInputError = 'Please enter a customer name';
                }
                if (typeof this.email !== 'string' || this.email.trim().length === 0 || !isValidEmail(this.email)) {
                    this.emailInputError = 'Please enter an email address';
                }
                if (this.displayNameInputError || this.emailInputError) {
                    this.inputErrorTimeout = setTimeout(() => { this.displayNameInputError = null; this.emailInputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }

                this.$store.commit('loading', { invite: true });
                const request = {
                    display_name: this.displayName,
                    email: this.email,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).user.invite(request);
                console.log('invite user response: %o', response);
                const { isInvited /* , id, error */ } = response;
                if (isInvited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Invited user', message: `${this.displayName} <${this.email}>` });
                    // reset so administrator can invite another user
                    this.displayName = '';
                    this.email = '';
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to send invitation', message: `${this.displayName} <${this.email}>` });
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { invite: false });
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
    },
    mounted() {
        // if user arrives with `email` and/or `displayName`, pre-fill the values but do not
        // submit the form
        if (this.$route.query.displayName) {
            this.displayName = this.$route.query.displayName;
        }
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }

        this.isViewReady = true;
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('displayNameInput'); }, 1);
        });
    },
};
</script>
